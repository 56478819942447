const paymentConditionInvoicingData = [
    {
        label: "Pós / Faturamento",
        value: "POS_FATURAMENTO"
    },
    {
        label: "Antecipado / Faturamento",
        value: "ANTECIPADO_FATURAMENTO"
    }
];

export default paymentConditionInvoicingData;